$animationSpeed: 20s;
$animationSpeedSlow: 40s;

// Animation
@keyframes scroll {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-230px * 4))}
}

@keyframes scroll-inverse {
  0% { transform: translateX(calc(-230px * 4)); }
  100% { transform: translateX(0)}
}

.MuiSlider-thumb{
  color: rgb(255,168,0);
}
.MuiSlider-thumb:hover{
  box-shadow:0px 0px 0px 8px rgba(255,168,0, 0.16) !important;
}
.MuiSlider-thumb:hover{
  box-shadow:0px 0px 0px 14px rgba(255,168,0, 0.16) !important;
}
.MuiSlider-thumb:focus-visible{
  box-shadow:0px 0px 0px 8px rgba(255,168,0, 0.16) !important;
}
.MuiSlider-thumb.Mui-active{
  box-shadow:0px 0px 0px 8px rgba(255,168,0, 0.16) !important;
}
.MuiSlider-thumb.Mui-focusVisible{
  box-shadow:0px 0px 0px 8px rgba(255,168,0, 0.16) !important;
}
.MuiSlider-rail{
  color:gray;
}
.MuiSlider-track{
  color: rgb(255,168,0);
}


.olf-page .MuiSlider-thumb{
  color: rgb(255,255,255);
}
.olf-page .MuiSlider-thumb:hover{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.olf-page .MuiSlider-thumb:hover{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.olf-page .MuiSlider-thumb:focus-visible{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.olf-page .MuiSlider-thumb.Mui-active{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.olf-page .MuiSlider-thumb.Mui-focusVisible{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.olf-page .MuiSlider-rail{
  color:gray;
}
.olf-page .MuiSlider-track{
  color: rgb(255,255,255);
}



.secret-garden-page .MuiSlider-thumb{
  color: rgb(0,0,0);
}
.secret-garden-page .MuiSlider-thumb:hover{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.secret-garden-page .MuiSlider-thumb:hover{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.secret-garden-page .MuiSlider-thumb:focus-visible{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.secret-garden-page .MuiSlider-thumb.Mui-active{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.secret-garden-page .MuiSlider-thumb.Mui-focusVisible{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.secret-garden-page .MuiSlider-rail{
  color:gray;
}
.secret-garden-page .MuiSlider-track{
  color: rgb(0,0,0);
}

.strandfuif-page .MuiSlider-thumb{
  color: rgb(40, 53, 131);
}
.strandfuif-page .MuiSlider-thumb:hover{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.strandfuif-page .MuiSlider-thumb:hover{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.strandfuif-page .MuiSlider-thumb:focus-visible{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.strandfuif-page .MuiSlider-thumb.Mui-active{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.strandfuif-page .MuiSlider-thumb.Mui-focusVisible{
  box-shadow:0px 0px 0px 8px rgba(255,255,255, 0.16) !important;
}
.strandfuif-page .MuiSlider-rail{
  color:gray;
}
.strandfuif-page .MuiSlider-track{
  color: rgb(40, 53, 131);
}