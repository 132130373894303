#demoEditor video{
  height: 250px;
}

#demoEditor .file-drop{
    width: 100%;
    height: fit-content;
    font-weight: bold;
    font-size: large;
    height: 100%;
}

#demoEditor .file-drop > .file-drop-target{
    transition: border-color .3s ease;
    background-color: white;
    color: white;
    text-align: center;
    cursor: pointer;
    border: 2px solid #CAB716;
    border-radius: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 250px;
    padding: 0;
}

#demoEditor .file-drop > .file-drop-target.file-drop-dragging-over-target{
    transition: border-color .3s ease;
    background-color: white;
    border: 2px solid #000;
}

#demoEditor .hiddenz{
    display: none;
}

#demoEditor .toolBar{
    padding: 1em;
    margin: 2em;
    text-align: center;
    border-radius: 1em;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

#demoEditor .togglez{
  background-color:rgb(185, 185, 185);
  color: yellow;
  height: 2em;
  width: 2em;
  text-align: center;
  line-height: 2em;
  vertical-align: middle;
  border-radius: 100em;
  font-size: x-large;
  z-index: 100;
  position: fixed;
  bottom: 1em;
  right: 1em;
  cursor: pointer;
  transition: background-color .2s ease;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

}

#demoEditor .toggle:hover{
  transition: background-color .2s ease;
  background-color:rgb(146, 146, 146);
}

#demoEditor .wrapper {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}


#demoEditor .MuiSlider-thumb{
    color: rgb(202,183,22);
}
#demoEditor .MuiSlider-thumb:hover{
    box-shadow:0px 0px 0px 8px rgba(202,183,22, 0.16) !important;
}
#demoEditor .MuiSlider-thumb:hover{
    box-shadow:0px 0px 0px 14px rgba(202,183,22, 0.16) !important;
}
#demoEditor .MuiSlider-thumb:focus-visible{
    box-shadow:0px 0px 0px 8px rgba(202,183,22, 0.16) !important;
}
#demoEditor .MuiSlider-thumb.Mui-active{
    box-shadow:0px 0px 0px 8px rgba(202,183,22, 0.16) !important;
}
#demoEditor .MuiSlider-thumb.Mui-focusVisible{
    box-shadow:0px 0px 0px 8px rgba(202,183,22, 0.16) !important;
}
#demoEditor .MuiSlider-rail{
    color:gray;
}
#demoEditor .MuiSlider-track{
    color: rgb(202,183,22);
}

/* Styling for Editing */
#demoEditor .video {
    width: 100%;
    cursor: pointer;
    height: 15em;
  }

#demoEditor .controls {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }
#demoEditor .player-controls {
    margin-left: auto;
    margin-right: auto;
  }

#demoEditor .controls .player-controls button {
    width: 34px;
    margin: 0 0.125rem;
  }
#demoEditor .controls .player-controls .play-control {
    background: #2f3b44;
    border: 0;
    color: #a5b0b5;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
#demoEditor .controls .player-controls .play-control:hover {
    background: #445562;
  }
#demoEditor .controls .player-controls .play-control:active {
    color: #ffffff;
  }
#demoEditor .controls .player-controls .seek-start {
    background: #2f3b44;
    border: 0;
    color: #a5b0b5;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
#demoEditor .controls .player-controls .seek-start:hover {
    background: #445562;
  }
#demoEditor .controls .player-controls .seek-start:active {
    color: #ffffff;
  }
#demoEditor .controls .player-controls .seek-end {
    background: #2f3b44;
    border: 0;
    color: #a5b0b5;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
#demoEditor .controls .player-controls .seek-end:hover {
    background: #445562;
  }
#demoEditor .controls .player-controls .seek-end:active {
    color: #ffffff;
  }
#demoEditor .controls .settings-control {
    background: #2f3b44;
    border: 0;
    color: #a5b0b5;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }

#demoEditor .controls .settings-control:active {
    color: #ffffff;
  }

#demoEditor .controls .settings-control:hover {
    background: #445562;
  }
#demoEditor .controls .trim-control {
    background: #0072cf;
    border: 0;
    color: #fff;
    padding: 0.5rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
#demoEditor .controls .trim-control:hover {
    background: #038eff;
  }

#demoEditor .margined{
    margin: 0em 0.3em;
  }

#demoEditor .warning{
    padding: 0.8em;
    color: #da7f00;
    background-color: #ffecd1;
    border-radius: 0.3em;
    border: 0.1em solid #f0ad4e;
    margin: 1em;
    justify-content: center;
    text-align: center;
  }

#demoEditor .thumbnail{
    width: 20%;
    height: auto;
    justify-content: center;
    align-items: center;
    border: 0.2em solid black;
    border-radius: 0.3em;
  }

#demoEditor .marginVertical{
    margin: 1.5em 0em;
  }

@media (max-width: 768px){
  #demoEditor .even-editor-row .fa-chevron-right{
    @apply rotate-90
  }
  #demoEditor .uneven-editor-row .fa-chevron-right{
    @apply rotate-90
  }
}

@media (min-width: 768px){
  #demoEditor .even-editor-row{

  }

  #demoEditor .uneven-editor-row{
    @apply flex-wrap-reverse
  }

  #demoEditor .uneven-editor-row .fa-chevron-right{
    @apply rotate-180
  }
}

