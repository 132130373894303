@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.strandfuif-page{
    font-family: Montserrat, sans-serif !important;
}

.strandfuif-page p{
    color: #0e0e0f;
}

.strandfuif-page .lightBlueColor {
    color: #55a3ab;
}

.strandfuif-page .bgLightBlue {
    background-color: #55a3ab;
}

.strandfuif-page .borderLightBlue {
    border-color: #55a3ab;
}

.strandfuif-page .darkBlueColor {
    color: #283583;
}

.strandfuif-page .bgDarkBlue {
    background-color: #283583;
}

.strandfuif-page .borderDarkBlue {
    border-color: #283583;
}