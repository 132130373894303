@font-face {
    font-family: 'Bagnard';
    src: url('../../../../Assets/fonts/b2b/secretgarden/BagnardSans.otf') format('opentype')
}

.secret-garden-page{
    font-family: Bagnard, sans-serif !important;
}

.secret-garden-page p{
    color: #0e0e0f;
}

.secret-garden-page .purpleColor {
    color: #732c6c;
}

.secret-garden-page .bgPurple {
    background-color: #732c6c;
}

.secret-garden-page .borderPurple {
    border-color: #732c6c;
}